import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlePreview from "../components/articlepreview";
import SEO from "../components/seo";
import styled from "@emotion/styled";
import { SidebarLayout, SidebarContent, Sidebar } from "../components/Sidebar";
import Img from "gatsby-image";

const Trending = styled.div`
  h4 {
    font-size: 26px;
    line-height: 1.2;
    font-weight: 700;
  }
  .trending-video {
    img {
      display: block;
    }
    h5 {
      font-size: 20px;
      font-weight: 700;
      margin: 6px 0;
    }
  }
`;

export default function Home({ data }) {
  const articles = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO title="Local News, Sports and More" />

      <SidebarLayout>
        <SidebarContent>
          {articles.map(({ node }, i) => (
            <ArticlePreview
              size={i === 0 ? "large" : "small"}
              key={node.id}
              meta={node.frontmatter}
              fields={node.fields}
            />
          ))}
        </SidebarContent>

        <Sidebar>
          <Img
            fixed={data.submitTip.childImageSharp.fixed}
            alt="submit a news tip"
          />
          <hr />
          <Trending>
            <h4>Trending Now</h4>

            <div className="trending-video">
              <Img
                fixed={data.herbie.childImageSharp.fixed}
                alt="Most Beautiful Cats"
              />
              <h5>
                WATCH: 'Beautiful Cats' Music Video Nails Cat Culture And Life
                In A Pandemic
              </h5>
            </div>
          </Trending>
        </Sidebar>
      </SidebarLayout>
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
      filter: {
        frontmatter: {unlisted: {ne: true}}
      }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
            category
          }
          frontmatter {
            youtube
            title
            tagline
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageAlt
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
        }
      }
    }

    herbie: file(
      relativePath: { eq: "images/herbie-2020-07-13-134917_001.png" }
    ) {
      childImageSharp {
        fixed(width: 320, height: 240) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    submitTip: file(relativePath: { eq: "images/submit-a-tip.png" }) {
      childImageSharp {
        fixed(width: 320, height: 180) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
