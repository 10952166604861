import styled from "@emotion/styled";
import mq from "../util/mediaQueries";

export const Sidebar = styled.div`
  ${mq[0]} {
    display: none;
  }
  ${mq[2]} {
    display: block;
  }
  width: 320px;
  margin-left: 30px;
  padding-top: 10px;
  img {
    width: 100%;
  }
`;

export const SidebarLayout = styled.div`
  display: flex;
`;

export const SidebarContent = styled.div`
  flex: 1;
`;
