import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import mq from "../util/mediaQueries";

const Container = styled.div(({ size }) =>
  size === "small"
    ? `
  ${mq[3]} {
    display: flex;
  }
`
    : `
  width: 100%;
`
);

const ArticleImageContainer = styled.div`
  position: relative;
  ${(props) =>
    props.size === "small"
      ? `
    ${mq[3]} {
      width: 320px;
      margin-right: 10px;
    }
  `
      : ""}
  background-color: black;
  margin-top: 10px;
  span {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: blue;
    color: white;
    text-transform: uppercase;
    padding: 10px 8px;
    font-size: 12px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const ArticleImage = ({ size, image, category, alt }) =>
  image && (
    <div>
      <ArticleImageContainer size={size}>
        <Img fluid={image.childImageSharp.fluid} alt={alt} />
        <span>{category}</span>
      </ArticleImageContainer>
    </div>
  );

const Title = styled.div`
  width: 100%;
`;

export default function ArticlePreview({ size, meta, fields }) {
  return (
    <Link to={`/articles/${fields.category}/${fields.slug}`}>
      <Container size={size}>
        <ArticleImage
          size={size}
          image={meta.image}
          category={fields.category}
          alt={meta.imageAlt}
        />
        <Title>
          <h3>
            {meta.youtube ? "WATCH: " : ""}
            {meta.title}
          </h3>
          <p>{meta.tagline}</p>
        </Title>
      </Container>
    </Link>
  );
}
